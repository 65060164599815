//测试

let url_base = 'http://49.234.130.43:9602'


export const URL_test = url_base+ '/test';
//登陆
export const URL_login = url_base+ '/api/login';
export const URL_hu_login = url_base + '/login'
//注册
export const URL_register = url_base+ '/register';

//忘记密码
export const URL_reset_password = url_base+ '/api/reset_password';

//修改密码
export const URL_modify_password =  '/api/modify_password';

//发送短信  /api/get_verification_code/{mobile}
export const URL_get_verification_code = '/api/get_verification_code/';
//注册公司

export const URL_vendor_account_profile_company = '/api/vendor/account/profile/company';

//根据名字查询公司

export const URL_vendor_account_query_company = '/api/vendor/account/query/company';


//图片解析
export const URL_vin_screenshot_analysis =url_base+'/api/vin/screenshot/analysis'
//获取autogroup
export const URL_vin_find_autogroups =url_base+'/api/vin/find/autogroups'

// 语音报价 启动标准化
export const URL_parts_text_to_stdname = url_base + '/text_to_stdname'
// 调货按钮
export const URL_shop_change = url_base + '/shop_change'
//零件识别接口图片解析
export const URL_parts_screenshot_analysis =url_base+'/api/parts/screenshot/analysis'
//昵称获取标准名:
export const URL_std_for_stdname =url_base+'/std_for_stdname'
//订单中心
export const URL_ai_order_list =url_base+'/api/ai/order/list'
//获取车型的主要特征
export const URL_vin_find_auto =url_base+'/api/vin/find/auto'
//截屏报价
export const URL_id_and_std_search =url_base+'/id_and_std_search'
//修改报价
export const URL_modify_price=url_base+'/modify_price'
//共享的查询
export const URL_search_sku =url_base+'/search_sku'
//对外库存共享
export const URL_share_for_other =url_base+'/share_for_other'
//
export const URL_share_for_price =url_base+'/share_for_price'
//向我共享
export const URL_share_for_me =url_base+'/share_for_me'
// 查看共享
export const URL_share_search = url_base+'/new_search'
export const URL_look_share_new = url_base+'/look_share_new'

// //
// export const URL_share_for_me_connect_seller =url1+'/share_for_meconnect_seller'
//商品列表
export const URL_api_parts_sku_list=url_base+'/api/parts/sku/list'


//商品设置价格修改
export const URL_api_goods_manage = url_base+'/goods_manage'

// 特价设置
export const URL_special_price_new = url_base + '/special_price'
export const URL_api_special_price = url_base + '/special_price'

//商品列表上下架
export const URL_api_parts_sku_onlineParts=url_base+'/api/parts/sku/onlineParts'
//商品列表删除
export const URL_api_parts_sku_delete=url_base+'/api/parts/sku/delete'
//商品新增
export const URL_api_parts_sku_getMarker=url_base+'/api/parts/sku/getMarker'
//商品上传excel
export const URL_api_parts_sku_importPartSKu=url_base+'/api/parts/sku/importPartSKu'
//下载模板
export const URL_excel_template_partsku=url_base+'/excel/template_partsku.xlsx'
//模糊查询零件
export const URL_api_parts_sku_getStdNameList=url_base+'/api/parts/sku/getStdNameList'
//获取配件品质
export const URL_api_parts_sku_getConfigQualitys=url_base+'/api/parts/sku/getConfigQualitys'
//获取质保期限
export const URL_api_parts_sku_getConfigWarrantys=url_base+'/api/parts/sku/getConfigWarrantys'
//商品保存提交
export const URL_api_parts_sku_save=url_base+'/api/parts/sku/save'
//商品图片的提交
export const URL_api_parts_sku_uploadPartImg=url_base+'/api/parts/sku/uploadPartImg'
//新增商品获取picId
export const URL_api_parts_sku_getPicId=url_base+'/api/parts/sku/getPicId'
//修改商品的详情页接口
export const URL_api_parts_sku_info=url_base+'/api/parts/sku/info'
//提交修改
export const URL_api_parts_sku_update=url_base+'/api/parts/sku/update'
//个人设置页面
export const URL_api_vendor_account_info=url_base+'/api/vendor/account/info'
//公司设置
export const URL_api_vendor_account_companyInfo=url_base+'/api/vendor/account/companyInfo'
//修改密码
export const URL_api_reset_password=url_base+'/api/reset_password'
//短信验证码
export const URL_api_get_verification_code=url_base+'/api/get_verification_code'

// 智能报价第三步获取报价
export const URL_gid_std_for_smartprice = url_base+'/gid_std_for_price'
//dashboart
export const URL_get_dashboard = url_base+ '/dashboard'
//品牌车型
export const URL_Brand_models =url_base+'/brand_maker';

export const URL_Model_Stdoe =url_base+'/model_stdoe';

export const URL_Price_cube = url_base+'/price_cube';

export const URL_Part_Adaper = url_base+'/part_adapter'

//报价中心

export const URL_Offer_record = url_base+'/offer_record'

//报价详情
export const URL_0ffer_history = url_base+'/offer_history'

// excel表格上传
export const URL_gid_std_for_price = url_base+'/gid_std_for_price'
export const URL_share_exeal = url_base+'/share_for_me'
export const URL_special_exeal = url_base+ '/special_price'
//订单中心

export const URL_Order_list = url_base+'/order_list'

export const URL_Order_center = url_base+'/order_center'

//汽修地图

export const URL_Auto_repair = url_base+'/repair_shop'

export const URL_my_favorite_repair_shop = url_base+'/my_favorite_repair_shop'

//查询marker
export const URL_api_parts_sku_searchMaker=url_base+'/api/parts/sku/searchMaker'
//查询model
export const URL_api_parts_sku_searchModel=url_base+'/api/parts/sku/searchModel'
//查询YearRange
export const URL_api_parts_sku_searchYearRange=url_base+'/api/parts/sku/searchYearRange'
//查询Capacity
export const URL_api_parts_sku_searchCapacity=url_base+'/api/parts/sku/searchCapacity'
//手选车型数据
export const URL_api_parts_sku_searchAutoGroup=url_base+'/api/parts/sku/searchAutoGroup'
//搜索车型代码
export const URL_api_parts_sku_searchModelCode=url_base+'/api/parts/sku/searchModelCode'
//加入购物车
export const URL_shop_car=url_base+'/shop_car'
//提示
export const URL_com=url_base+'/tuisong'
//调货列表
export const URL_change_shop=url_base+'/change_shop'
//特价
export const URL_special_price=url_base+'/special_price'

// 智能EPC
export const URL_epc_brand = url_base +'/brand'
export const URL_epc_group_mapping = url_base + '/groupid_mapping'

// 智能销售
export const URL_smart_sale = url_base + '/smart_sale'
export const URL_smartepc_save = url_base + '/sale_save'

//保险直供模块
export const URL_chat = url_base + '/chat'
export const URL_risk = url_base + '/risk'
export const URL_pricing = url_base + '/pricing'

// 核价统计
export const URL_statistics =  url_base + '/statistics'

// 人员设置
export const URL_worker_manage =  url_base + '/worker_manage'

// 最新登录相关接口
export const URL_hu_new_login = url_base + '/login'
export const URL_my_setting = url_base + '/my_setting'

// 智配宝epc接口
export const URL_epc_new_brand = url_base +'/brand'
export const URL_epc_new_group_mapping = url_base + '/groupid_mapping'

//  后台管理
export const adminDashboardUrl = url_base + '/dashboard'
export const adminLoginUrl = url_base + '/login'
export const adminRepairUrl = url_base + '/repair_shop'   //汽修地图
export const adminSettingUrl = url_base + '/my_setting'   //汽修地图
export const adminOrderToumingxiu = url_base + '/order_zhipeibao/'
export const orderToumingxiu = url_base + '/received_order_toumingxiu/search/'
export const orderToumingxiuDetail = url_base + '/received_order_toumingxiu/detail/'

// export const adminOrder = url10 + '/order/'
export const adminOrder = url_base + '/order_yingda/query/'

// export const URL_logistics_save = url10 + '/order_toumingxiu'
export const URL_logistics_save = url_base + '/upload/'
// export const URL_zhiPeiBao_order = url10 + '/order_zhipeibao/'
export const URL_zhiPeiBao_order = url_base + '/order_zhipeibao/'
export const URL_search_order = url_base + '/search/'
// export const URL_search_order = url10 + '/search/'

//url10
export const vendor_manager_show = url_base + '/vendor_manager/show/'

export const vendor_manager_user_show = url_base + '/vendor_manager/user_show/'
export const vendor_manager_user_change = url_base + '/vendor_manager/user_change/'
export const vendor_manager_user_add = url_base + '/vendor_manager/user_add/'
export const vendor_manager_user_delete = url_base + '/vendor_manager/user_delete/'
export const vendor_manager_create = url_base + '/vendor_manager/create/'
export const vendor_manager_get_makers = url_base + '/vendor_manager/get_makers/'
export const vendor_manager_delete = url_base + '/vendor_manager/delete/'
export const vendor_manager_search = url_base + '/vendor_manager/search/'
export const vendor_manager_change = url_base + '/vendor_manager/change/'
export const worker_manager_show = url_base + '/worker_manage/show/'
export const worker_manager_change = url_base + '/worker_manage/change/'
export const worker_manager_create = url_base + '/worker_manage/create/'
export const vendor_manager_punish = url_base + '/vendor_manager/punish/'
export const worker_manage_show = url_base + '/worker_manage/show/'
export const URL_tongji = url_base + '/tongji/'
export const URL_tongji1 = url_base + '/tongji/show/'
export const URL_tongji2 = url_base + '/tongji/search/'

export const banner_add= url_base + '/banner/add/';
export const banner_delete= url_base + '/banner/delete/';
export const banner_query= url_base + '/banner/query/';

/** 自动发送询价单 & 发送询价单给指定配件商 */
export const vendor_company=url_base+'/enquiry_manager/vendor_company/'
/** 获取匹配的配件商*/
export const auto_send=url_base+'/enquiry_manager/auto_send/'
export const send=url_base+'/enquiry_manager/send/'
export const get_code=url_base+'/get_code/'
export const change_password=url_base+'/change_password/'
