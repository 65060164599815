import React, { Component } from 'react'

// export default class GlobalBadge extends Component {
//     render() {
//         return (
//             <div>
//                 123
//             </div>
//         )
//     }
// }
export default function GlobalBadge() {
    return `
        <div class='global-badge'>
          <span>
           您有新的订单消息： ${localStorage.getItem('badgeCount')}
          </span>
        </div>
    `
}
