import React, { Component } from 'react'
import { Form, Input, Icon, Button, AutoComplete } from 'antd'
import $ from 'jquery'
import { message } from 'antd'
const FormItem = Form.Item
export default class Register extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchName: '',
            dataSource: [],
            url: this.props.regForm.url,
            editStatu: true
        }
    }
    // 表单提交
    handleSubmit = (err, val) => {
        let { regForm, regPhone } = this.props
        let { url, editStatu } = this.state
        if (editStatu) {
            if (regForm.company_name && regForm.company_name != '') {
                let { regForm, regPhone } = this.props
                $.ajax({
                    url,
                    type: 'POST',
                    data: {
                        regForm: JSON.stringify(regForm),
                        regPhone
                    },
                    success: (res) => {
                        if (res.code == 1) {
                            message.success(res.messages);
                            this.props.goLogin()
                        } else {
                            message.error(res.messages)
                        }
                    }
                })

            } else {
                message.warning('请输入公司名称')
            }
        } else{
           let companyName = this.props.form.getFieldValue('regForm.company_name');
           let province = this.props.form.getFieldValue('regForm.province');
           let city = this.props.form.getFieldValue('regForm.city');
           let country = this.props.form.getFieldValue('regForm.country');
           let address = this.props.form.getFieldValue('regForm.address');
           let phone = this.props.form.getFieldValue('regForm.phone');
           let regFrom = {}
           regForm.company_name = companyName
           regForm.city = city
           regForm.province = province
           regForm.country = country
           regForm.address = address
           regForm.phone = phone
           this.props.vaildCompany(regForm)
           
        }

    }
    handleSearch = (val) => {
        let reg = /^[\u4E00-\u9FA5]{2,20}$/g; //判断是否两个汉字以上
        if (reg.test(val)) {
            let { url } = this.state
            $.ajax({
                url,
                type: 'POST',
                data: {
                    s_company: val
                },
                success: (res) => {
                    this.setState({
                        dataSource: res
                    })
                }
            })
        } else if (val == '') {
            this.setState({
                dataSource: []
            })
        }
    }
    regBack = () => {
        this.props.regBack()
    }
    // 选中公司时
    selectedCompony = (val) => {
        this.setState({
            editStatu: true
        })
        this.props.selectedCompony(val)
    }
    // 创建新公司
    createCompany = () => {
        this.setState({
            editStatu: false
        })
    }
    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        const { dataSource } = this.state
        const { editStatu } = this.state
        return (
            <>
                <div className='login-logo'>
                    加入已有公司
                </div>
                <Form onSubmit={this.handleSubmit}>
                    <FormItem>
                        <AutoComplete
                            dataSource={dataSource}
                            onSearch={this.handleSearch}
                            onSelect={this.selectedCompony}
                            allowClear
                        >
                            <Input size='large' prefix={<Icon type="user" style={{ fontSize: 14 }} />} placeholder="请输入公司名称搜索" />
                        </AutoComplete>

                    </FormItem>
                    <FormItem>
                        <Button type='primary' onClick={this.createCompany}>创建新公司</Button>
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('regForm.company_name', {
                            rules: [{ required: true, message: '请输入公司全称' }],
                            initialValue: this.props.regForm.company_name
                        })(
                            <Input size='large' disabled={editStatu} prefix={<Icon type="user" style={{ fontSize: 14 }} />} placeholder="请输入公司全称" />
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('regForm.province', {
                            rules: [{ required: true, message: '请选择省份' }],
                            initialValue: this.props.regForm.province
                        })(
                            <Input size='large' disabled={editStatu} prefix={<Icon type="environment" style={{ fontSize: 14 }} />} placeholder="请选择省份" />
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('regForm.city', {
                            rules: [{ required: true, message: '请选择市区' }],
                            initialValue: this.props.regForm.city
                        })(
                            <Input size='large' disabled={editStatu} prefix={<Icon type="environment" style={{ fontSize: 14 }} />} placeholder="请选择市区" />
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('regForm.country', {
                            rules: [{ required: true, message: '请选择区域' }],
                            initialValue: this.props.regForm.country
                        })(
                            <Input size='large' disabled={editStatu} prefix={<Icon type="environment" style={{ fontSize: 14 }} />} placeholder="请选择区域" />
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('regForm.address', {
                            rules: [{ required: true, message: '请输入详细地址' }],
                            initialValue: this.props.regForm.address
                        })(
                            <Input size='large' disabled={editStatu} prefix={<Icon type="environment" style={{ fontSize: 14 }} />} placeholder="请输入详细地址" />
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('regForm.phone', {
                            rules: [{ required: true, message: '请输入联系电话' }],
                            initialValue: this.props.regForm.phone
                        })(
                            <Input size='large' disabled={editStatu} prefix={<Icon type="phone" style={{ fontSize: 14 }} />} placeholder="请输入联系电话" />
                        )}
                    </FormItem>
                    <FormItem>
                        <Button type='primary' size='large' onClick={this.regBack}>返回</Button>
                        <Button
                            size='large'
                            type="primary"
                            htmlType="submit"
                        >
                            注册完成
                        </Button>
                    </FormItem>
                </Form>
            </>
        )
    }
}
