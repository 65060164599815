import React, { Component } from 'react'

export default class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			error: ""
		};
	}
 
	componentDidCatch(error, info) {
		this.setState({
			hasError: true,
			error: error
		});
	}
	render() {
		if(this.state.hasError) {
			return (
                <div>
                  <h1>数据异常</h1>
                  <h2>{this.state.error.toString()}</h2>
                </div>
            )
		}
        return this.props.children;
    }
}
