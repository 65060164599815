export const USER_INFO_SAVE = (value)=>{
    localStorage.setItem('admin', JSON.stringify(value));
}

export const USER_INFO_GET = ()=>{
    return JSON.parse(localStorage.getItem('admin'));
}

export const USER_INFO_REMOVE = () => {
    localStorage.removeItem('admin')
}


