import React, {
	Component,
	Suspense
} from 'react';
import {
	Layout,
	notification,
	Icon
} from 'antd';
import SiderCustom from './components/SiderCustom';
import HeaderCustom from './components/HeaderCustom';
import ErrorBoundary from './components/ErrorBoundary';
import {
	USER_INFO_GET,
	USER_INFO_REMOVE
} from "@/utils/storeInfo";
// import moment from 'moment';
// moment.locale('zh-cn');
import {
	Spin
} from 'antd'
import {
	receiveData
} from './action';
import {
	connect
} from 'react-redux';
import {
	bindActionCreators
} from 'redux';
import Routes from './routes';
import {
	ThemePicker
} from './components/widget';

const {
	Content,
	Footer
} = Layout;

class App extends Component {
	state = {
		collapsed: false,
	};
	componentWillMount() {
		const {
			receiveData
		} = this.props;
		const user = JSON.parse(localStorage.getItem('admin'));
		user && receiveData(user, 'auth');
		// receiveData({a: 213}, 'auth');
		// fetchData({funcName: 'admin', stateName: 'auth'});
		this.getClientWidth();
		window.onresize = () => {
			this.getClientWidth();
		}
		// if(!USER_INFO_GET()) {
		//     this.props.history.push('/login')
		// }
	}
	componentWillUnmount() {
		USER_INFO_REMOVE()
	}
	getClientWidth = () => { // 获取当前浏览器宽度并设置responsive管理响应式
		const {
			receiveData
		} = this.props;
		const clientWidth = window.innerWidth;
		console.log(clientWidth);
		receiveData({
			isMobile: clientWidth <= 992
		}, 'responsive');
	};
	toggle = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};
	render() {
		const {
			auth,
			responsive
		} = this.props;
		return ( <
			Layout > { /*{!responsive.data.isMobile && <SiderCustom collapsed={this.state.collapsed} />}*/ } <
			SiderCustom collapsed = {
				this.state.collapsed
			}
			/> { /*<ThemePicker />*/ } <
			Layout style = {
				{
					flexDirection: 'column',
					marginLeft: '75px',
					overflow:'visible'
				}
			} >
			<
			HeaderCustom toggle = {
				this.toggle
			}
			collapsed = {
				this.state.collapsed
			}
			user = {
				auth.data || {}
			}
			/> <
			Content style = {
				{
					margin: '0 16px',
					overflow: 'initial',
					flex: '1 1 0',
					marginTop: '55px'
				}
			} >
			<
			ErrorBoundary >
			<
			Routes auth = {
				auth
			}
			/> <
			/ErrorBoundary> <
			/Content> <
			Footer style = {
				{
					textAlign: 'center',
					paddingBottom: 0,
					paddingTop: 12
				}
			} > { /* 天鲲Ai   {new Date().getFullYear()} */ } <
			/Footer> <
			/Layout>

			{
				/* {
				                    responsive.data.isMobile && (   // 手机端对滚动很慢的处理
				                        <style>
				                        {`
				                            #root{
				                                height: auto;
				                            }
				                        `}
				                        </style>
				                    )
				                } */
			} <
			/Layout>
		);
	}
}

const mapStateToProps = state => {
	const {
		auth = {
				data: {}
			},
			responsive = {
				data: {}
			}

	} = state.httpData;
	return {
		auth,
		responsive
	};
};
const mapDispatchToProps = dispatch => ({
	receiveData: bindActionCreators(receiveData, dispatch)
});

export default connect(state => ({
	auth: {
		data: {},
		...state.httpData.auth
	},
	responsive: {
		data: {},
		...state.httpData.responsive
	}
}), mapDispatchToProps)(App);
