/**
 * Created by 叶子 on 2017/7/30.
 */

//请求数据
export const REQUEST_DATA = 'REQUEST_DATA';
//接收数据
export const RECEIVE_DATA = 'RECEIVE_DATA';

export const LOGIN_INFO = 'logni_info';//登陆信息



