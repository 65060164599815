/**
 * Created by hao.cheng on 2017/4/13.
 */
import React, { Component } from 'react';
import { Menu, Icon, Layout, Badge, Popover } from 'antd';
import screenfull from 'screenfull';
import { gitOauthToken, gitOauthInfo } from '../axios';
import { queryString } from '../utils';
import avater from '../style/imgs/b1.jpg';
import carts from '../style/imgs/carts.png';
import SiderCustom from './SiderCustom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PwaInstaller } from './widget';
import routesConfig from '@/routes/config';
import { Link } from 'react-router-dom';
import '@/style/globalheader.less'
const { Header } = Layout;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class HeaderCustom extends Component {
    state = {
        user: '',
        visible: false,
        router: []
    };
    componentDidMount() {
        const QueryString = queryString();
        const _user = JSON.parse(localStorage.getItem('user')) || '测试';
        if (!_user && QueryString.hasOwnProperty('code')) {
            gitOauthToken(QueryString.code).then(res => {
                gitOauthInfo(res.access_token).then(info => {
                    this.setState({
                        user: info
                    });
                    localStorage.setItem('user', JSON.stringify(info));
                });
            });
        } else {
            this.setState({
                user: _user
            });
        }
    };
    screenFull = () => {
        if (screenfull.enabled) {
            screenfull.request();
        }

    };
    menuClick = e => {
        e.key === 'logout' && this.logout();
        e.key === 'logout1' && this.logout1();
        e.key === 'cart' && this.carts();
    };
    logout = () => {
        localStorage.removeItem('user');
        this.props.history.push('/login')
    };
    logout1 = () => {
        localStorage.removeItem('user');
        this.props.history.push('/login')
    };
    carts = () => {
        console.log(this.props)
        this.props.history.push('/app/carts')
    }
    popoverHide = () => {
        this.setState({
            visible: false,
        });
    };
    handleVisibleChange = (visible) => {
        this.setState({ visible });
    };
    componentDidMount() {
        const { pathname } = this.props.location
        const { menus } = routesConfig
        let list = []
        if (sessionStorage.getItem('route')) {
          const route = sessionStorage.getItem('route')
          list = JSON.parse(route)
        } else {
            menus.forEach((menu) => {
                if (menu.key === pathname) {
                    if (menu.subs) {
                        list = menu.subs
                    } else {
                        list.push(menu)
                    }
                }
            })
        }
        this.setState({
            router: list
        })
    }
    render() {
        const { responsive, path } = this.props;
        const { pathname } = this.props.location
        let list = []
        const { menus } = routesConfig
        menus.forEach((menu) => {
            if(menu.key === pathname) {
                if(menu.subs) {
                    list = menu.subs
                } else {
                    list.push(menu)
                }
            } else if(menu.subs) {
                const subs = menu.subs
                subs.forEach((sub) => {
                   if(sub.key === pathname) {
                       list = menu.subs
                   }
                })
            }
        })
        return (
            <Header className="custom-theme header" style={{position: 'fixed',width: '100%'}}>
                {/* {
                    responsive.data.isMobile ? (
                        <Popover content={<SiderCustom path={path} popoverHide={this.popoverHide} />} trigger="click" placement="bottomLeft" visible={this.state.visible} onVisibleChange={this.handleVisibleChange}>
                            <Icon type="bars" className="header__trigger custom-trigger" />
                        </Popover>
                    ) : (
                        <Icon
                            className="header__trigger custom-trigger"
                            type={this.props.collapsed ? 'menu-unfold' : 'menu-fold'}
                            onClick={this.props.toggle}
                        />
                    )
                } */}
                <div className='left'>
                    {list && list.map((item, index) =>
                        <div
                            key={index}
                            className={`${item.key === pathname ? "route" : ''}`}
                        >
                            <Link to={item.key}>
                                {item.icon && <Icon type={item.icon} />}
                                <span>{item.title}</span>
                            </Link>
                        </div>
                    )}
                </div>
                <Menu
                    mode="horizontal"
                    onClick={this.menuClick}
                    className='right'
					style={{marginRight: '50px'}}
                >
                    <Menu.Item key="pwa">
                        <PwaInstaller />
                    </Menu.Item>
                    <Menu.Item key="cart">
                        {/* <span onClick={this.carts}>
                            <i className="iconfont icon-gouwuche" style={{ display: 'inline-block', cursor: 'pointer', fontSize: '20px' }}></i>
                        </span> */}
                    </Menu.Item>
                    <Menu.Item key="full" >
                        {/* <Icon type="arrows-alt" onClick={this.screenFull} /> */}
                    </Menu.Item>
                    {/*<Menu.Item key="1">*/}
                    {/*<Badge count={25} overflowCount={10} style={{marginLeft: 10}}>*/}
                    {/*<Icon type="notification" />*/}
                    {/*</Badge>*/}
                    {/*</Menu.Item>*/}
                    <SubMenu title={<span className="avatar"><img src={avater} alt="头像" /><i className="on bottom b-white" /></span>}>
                        {/*<MenuItemGroup title={"你好 - "+this.props.user.userName}>*/}

                        {/*  <Menu.Item key="setting:1">个人设置</Menu.Item>
                            <Menu.Item key="setting:2">公司设置</Menu.Item>
                            <Menu.Item key="setting:3">账户续费</Menu.Item>
                            <Menu.Item key="setting:4">密码修改</Menu.Item>*/}
                        <Menu.Item key="logout1"><span onClick={this.logout1}>切换账号</span></Menu.Item>
                        <Menu.Item key="logout"><span onClick={this.logout}>退出登录</span></Menu.Item>
                        {/*</MenuItemGroup>*/}
                    </SubMenu>
                </Menu>
            </Header>
        )
    }
}

const mapStateToProps = state => {
    const { responsive = { data: {} } } = state.httpData;
    return { responsive };
};

export default withRouter(connect(mapStateToProps)(HeaderCustom));
