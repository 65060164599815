/**
 * Created by hao.cheng on 2017/4/22.
 */
import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

class BreadcrumbCustom extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        const pathName = this.props.pathname;
        let firstName = '';
        let secondName = '';
        switch(pathName) {
            case '/app/smartquotation':
                firstName = '智能报价';
                secondName = '智能报价';
                break; 
            case '/app/epc':
                firstName = '智能EPC';
                break;
            case '/app/insurance':
                firstName = '智能保险';
                break;
            case '/app/goodsmanage':
                firstName = '智能报价';
                secondName = '商品设置';
                break;                   
            case '/app/goodsaddition':
                firstName = '智能报价';
                secondName = '添加商品';
                break;                 
            case '/app/mystock':
                firstName = '库存共享';
                secondName = '查看共享';
                break;    
            case '/app/stockview':
                firstName = '库存共享';
                secondName = '发布共享';
                break;  
            case '/app/stocklist':
                firstName = '库存共享';
                secondName = '共享设置';
                break;                    
            case '/app/specialview':
                firstName = '特价清仓';
                secondName = '查看特价';
                break;
            case '/app/specialmanage':
                firstName = '特价清仓';
                secondName = '特价设置';
                break;                 
            case '/app/AutoRepairDatabase':
                firstName = '汽修地图';
                secondName = '汽修数据库';
                break;  
            case '/app/MyCollection':
                firstName = '汽修地图';
                secondName = '我的收藏';
                break;                  
            case '/app/offerlist':
                firstName = '报价记录';
                secondName = '报价记录';
                break;                 
            case '/app/transferlist':
                firstName = '采购记录';
                secondName = '同行采购';
                break;               
            case '/app/salelist':
                firstName = '销售记录';
                secondName = '同行销售';
                break;
            case '/app/user/person':
                firstName = '我的账户';
                secondName = '个人设置';
                break;
            case '/app/user/company':
                firstName = '我的账户';
                secondName = '公司设置';
                break;      
            case '/app/user/pwd':
                firstName = '我的账户';
                secondName = '密码修改';
                break;   
            default:
                firstName = '';
                secondName = '';
        }
        const first = <Breadcrumb.Item>{firstName}</Breadcrumb.Item> || '';
        const second = <Breadcrumb.Item>{secondName}</Breadcrumb.Item> || '';
        return (
            <span>
                <Breadcrumb style={{ margin: '12px 0' }}>
                    {/* <Breadcrumb.Item><Link to={'/app/dashboard/index'}>首页</Link></Breadcrumb.Item> */}
                        {first}
                        {second}
                </Breadcrumb>
            </span>
        )
    }
}

export default BreadcrumbCustom;
